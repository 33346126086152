import { createRouter, createWebHistory } from 'vue-router'
import { useStoreConfig } from '@/stores/config'
import { useStoreUserInfo } from '@/stores/userInfo'
import { useStoreMessages } from '@/stores/messages'
import { getVueName } from '@/utils/vueUtil'

//ルーター定義
const routes = [
  //TOP
  {
    path: '/',
    name: 'home',
    component: () => import('../views/' + getVueName('welcome/WelcomePage.vue'))
  },
  {
    path: '/checkage',
    name: 'checkage',
    components: {
       window: () => import('../views/' + getVueName('user/CheckAgePage.vue'))
    },
    meta: { notRequiresAgeCheck: true }
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    components: {
      window: () => import('../views/' + getVueName('user/ForbiddenPage.vue')),
   },
    meta: { notRequiresAgeCheck: true }
  },
  //年齢確認
  {
    path: '/checkage_item',
    name: 'checkage_item',
    component: () => import('../views/' + getVueName('user/CheckAgeItemPage.vue')),
    meta: { notRequiresAgeCheck: true }
  },
  //ログイン
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/' + getVueName('user/LoginPage.vue')),
  },
  //ログアウト
  {
    name: 'logout',
    path: '/logout',
    component: () => import('../views/' + getVueName('user/LogOutPage.vue')),
    meta: { notRequiresAgeCheck: true }
  },
  //マイページ_会員情報
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/' + getVueName('user/UserDetailPage.vue')),
    meta: { requiresAuth: true }
  },
  //会員情報の変更
  {
    path: '/user/edit',
    name: 'user_edit',
    component: () => import('../views/' + getVueName('user/UserChangePage.vue')),
    meta: { requiresAuth: true }
  },
  //会員登録_メール送信完了
  {
    path: '/user/registrations',
    name: 'user_registrations',
    component: () => import('../views/' + getVueName('registration/RegistrationMailTransPage.vue'))
  },
  //会員登録_メールアドレス入力
  {
    path: '/user/registrations/new',
    name: 'user_registrations_new',
    component: () => import('../views/' + getVueName('registration/RegistrationMailPage.vue'))
  },
  //会員登録_詳細入力
  {
    path: '/user/registrations/:id/edit',
    name: 'user_registrations_uuid_edit',
    component: () => import('../views/' + getVueName('registration/RegistrationDetailPage.vue'))
  },
  //会員登録_内容確認
  {
    path: '/user/confirm',
    name: 'user_confirm',
    component: () => import('../views/' + getVueName('registration/RegistrationConfirmPage.vue'))
  },
  //会員登録_完了
  {
    path: '/user/done',
    name: 'user_done',
    component: () => import('../views/' + getVueName('registration/RegistrationCompletePage.vue'))
  },
  //販売中くじ一覧
  {
    path: '/lottery/onsales',
    name: 'lottery_onsales',
    component: () => import('../views/' + getVueName('lottery/LotteryIndexPage.vue')),
    props: { salesType: 1 },
  },
  //販売予定くじ一覧
  {
    path: '/lottery/comings',
    name: 'lottery_comings',
    component: () => import('../views/' + getVueName('lottery/LotteryIndexPage.vue')),
    props: { salesType: 2 },
  },
  //販売終了くじ一覧
  {
    path: '/lottery/archives',
    name: 'lottery_archives',
    component: () => import('../views/' + getVueName('lottery/LotteryIndexPage.vue')),
    props: { salesType: 3 },
  },
  //くじ検索
  {
    path: '/lottery/search/:keyword',
    name: 'lottery_search',
    component: () => import('../views/' + getVueName('lottery/LotterySearchPage.vue'))
  },
  //お知らせ
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/' + getVueName('news/NewsIndexPage.vue'))
  },
  //お知らせ詳細
  {
    path: '/news/:id',
    name: 'news_detail',
    component: () => import('../views/' + getVueName('news/NewsDetailPage.vue'))
  },
  //マイページ_購入履歴
  {
    path: '/purchases',
    name: 'purchases',
    component: () => import('../views/' + getVueName('purchases/PurchasesIndexPage.vue')),
    meta: { requiresAuth: true }
  },
  //マイページ_購入履歴詳細
  {
    path: '/purchases/:identificationName',
    name: 'purchases_detail',
    component: () => import('../views/' + getVueName('purchases/PurchasesDetailPage.vue'))
  },
  //マイページ_選択賞品一覧
  {
    path: '/purchases/:identificationName/:orderId/select',
    name: 'purchases_select',
    component: () => import('../views/' + getVueName('purchases/SelectPage.vue')),
    meta: { requiresAuth: true }
  },
  //当選賞品一覧
  {
    path: '/purchases/:identificationName/prizes',
    name: 'purchases_prizes',
    component: () => import('../views/' + getVueName('purchases/PrizeListPage.vue')),
  },
  //くじ詳細
  {
    path: '/lotteries/:identificationName',
    name: 'lotteries',
    component: () => import('../views/' + getVueName('lotteries/ShowPage.vue'))
  },
  //グループ一覧
  {
    path: '/lotteries_group/:identificationName',
    name: 'lotteries_group',
    component: () => import('../views/' + getVueName('lotteries_group/LotteriesGroupShowPage.vue'))
  },
  //くじ購入
  {
    path: '/lotteries/:identificationName/orders/new',
    name: 'lotteries_order',
    component: () => import('../views/' + getVueName('lotteries/orders/OrdersNew.vue'))
  },
  //くじ購入(GMOフォーム)
  {
    path: '/lotteries/:identificationName/orders/gmo/new',
    name: 'lotteries_order_gmo',
    component: () => import('../views/' + getVueName('lotteries/orders/GmoForm.vue'))
  },
  //くじ購入(PayPayフォーム)
  {
    path: '/lotteries/:identificationName/orders/paypay/new',
    name: 'lotteries_order_paypay',
    component: () => import('../views/' + getVueName('lotteries/orders/PayPayForm.vue'))
  },
  //GMOリザルト
  {
    path: '/payments',
    name: 'payments',
    component: () => import('../views/' + getVueName('payments/PaymentCreate.vue'))
  },
  //PayPayリザルト
  {
    path: '/paypay/payments/:paymentId',
    name: 'paypay_payments',
    component: () => import('../views/' + getVueName('payments/PayPayPayment.vue'))
  },
  //抽選応募履歴
  {
    path: '/more_chan',
    name: 'more_chan',
    component: () => import('../views/' + getVueName('moreChan/MoreChanIndexPage.vue')),
    meta: { requiresAuth: true }
  },
  //モアチャン_抽選特典応募フォーム_シリアルコード入力
  {
    path: '/more_chan/new',
    name: 'more_chan_new',
    component: () => import('../views/' + getVueName('moreChan/MoreChanCreatePage.vue')),
    meta: { requiresAuth: true }
  },
  //モアチャン_抽選特典応募フォーム_応募完了
  {
    path: '/more_chan/result',
    name: 'more_chan_result',
    component: () => import('../views/' + getVueName('moreChan/MoreChanResultPage.Vue')),
    meta: { requiresAuth: true }
  },
  //くじ抽選動画演出
  {
    path: '/lotteries/:identificationName/:orderId/challenge/movie',
    name: 'lotteries_challenge_movie',
    component: () => import('../views/' + getVueName('lotteries/challenges/ChallengesMovie.vue'))
  },
  //くじ抽選
  {
    path: '/lotteries/:identificationName/:orderId/challenge',
    name: 'lotteries_challenge',
    component: () => import('../views/' + getVueName('lotteries/challenges/ChallengesNew.vue'))
  },
  //くじ抽選結果
  {
    path: '/lotteries/:identificationName/:orderId/challenge/list',
    name: 'lotteries_challenge_list',
    component: () => import('../views/' + getVueName('lotteries/challenges/ChallengesShow.vue'))
  },
  //BOX内訳
  {
    path: '/lotteries/:id/prizes',
    name: 'lotteries_prizes',
    component: () => import('../views/' + getVueName('lotteries/prizes/PrizesIndexPage.vue'))
  },
  //販売店舗
  {
    path: '/lotteries/:identificationName/sale_stores',
    name: 'lotteries_sale_stores',
    component: () => import('../views/' + getVueName('lotteries/saleStores/SaleStoresIndexPage.vue'))
  },
  //注意事項
  {
    path: '/page/notice',
    name: 'page_notice',
    component: () => import('../views/' + getVueName('pages/NoticePage.vue'))
  },
  //プライバシーポリシー
  {
    path: '/page/privacy',
    name: 'page_privacy',
    component: () => import('../views/' + getVueName('pages/PrivacyPage.vue'))
  },
  //利用規約
  {
    path: '/page/agreement',
    name: 'page_agreement',
    component: () => import('../views/' + getVueName('pages/AgreementPage.vue'))
  },
  //よくあるご質問
  {
    path: '/page/faq',
    name: 'page_faq',
    component: () => import('../views/' + getVueName('pages/FaqPage.vue'))
  },
  //特定商取引法に基づく表記
  {
    path: '/page/law',
    name: 'page_law',
    component: () => import('../views/' + getVueName('pages/LawPage.vue'))
  },
  //ご利用ガイド
  {
    path: '/page/user_guide',
    name: 'page_user_guide',
    component: () => import('../views/' + getVueName('pages/UserGuidePage.vue'))
  },
  //メンテナンス
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('../views/' + getVueName('pages/MaintenancePage.vue')),
    meta: { notRequiresAgeCheck: true }
  },
  //お問い合わせ入力
  {
    path: '/contacts/new',
    name: 'contacts_new',
    component: () => import('../views/' + getVueName('contacts/ContactsCreatePage.vue'))
  },
  //お問い合わせ確認
  {
    path: '/contacts/confirms',
    name: 'contacts_confirms',
    component: () => import('../views/' + getVueName('contacts/ContactsConfirmPage.vue'))
  },
  //お問い合わせ完了
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/' + getVueName('contacts/ContactsResultPage.vue'))
  },
  //パスワード再設定_メールアドレス入力
  {
    path: '/password/changes/new',
    name: 'password_changes_new',
    component: () => import('../views/' + getVueName('password/changes/PasswordForgetPage.vue'))
  },
  //パスワード再設定_メール送信完了
  {
    path: '/password/changes',
    name: 'password_changes',
    component: () => import('../views/' + getVueName('password/changes/PasswordMailTransPage.vue'))
  },
   //パスワード再設定
   {
    path: '/password/changes/:digest/edit',
    name: 'password_changes_edit',
    component: () => import('../views/' + getVueName('password/changes/PasswordChangePage.vue'))
  },
  //パスワード変更
  {
    path: '/password/edit',
    name: 'password_edit',
    component: () => import('../views/' + getVueName('passwords/PasswordEditPage.vue'))
  },
  //メールアドレスの変更
  {
    path: '/email/changes/new',
    name: 'email_changes_new',
    component: () => import('../views/' + getVueName('email/changes/EmailChangePage.vue'))
  },
  //メールアドレスの変更_メール送信完了
  {
    path: '/email/changes',
    name: 'email_changes',
    component: () => import('../views/' + getVueName('email/changes/EmailTransPage.vue'))
  },
  //メールアドレスの変更_変更完了
  {
    path: '/email/changes/:digest/edit',
    name: 'email_changes_edit',
    component: () => import('../views/' + getVueName('email/changes/EmailEditCompletePage.vue'))
  },
  //ポイント交換
  {
    path: '/orders/:orderId/point_exchanges',
    name: 'orders_point_exchanges',
    component: () => import('../views/' + getVueName('orders/PointExchanges.vue'))
  },
  { //デフォルト
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' }
  }
]

//router作成
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) { //画面遷移時一番上へ
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
})

//画面遷移前の処理
router.beforeEach((to, from, next) => {

  const messages = useStoreMessages()
  const config = useStoreConfig()
  const userInfo = useStoreUserInfo()

  //メッセージ一覧をクリア
  messages.$reset()

  //メンテナンス中
  if (config.underMaintenance) {
    //メンテ画面にリダイレクトをする時、もう一回ここに来る
    if (to.name != 'maintenance') {
      next({ name: 'maintenance' })
      return
    }
    else {
      next()
      return
    }
  }

  //サイト年齢確認要、年齢未確認、年齢確認不要画面以外（年齢確認など）の場合、年齢確認へ
  if ((config.adultCheck && !userInfo.ageChecked)
    && !to.matched.some(record => record.meta.notRequiresAgeCheck)) {
    next({ name: 'checkage', query: { redirect: to.fullPath } })
    return
  }

  //login処理
  if (to.matched.some(record => record.meta.requiresAuth) && !userInfo.authenticated) {
    next({ name: 'login', query: { redirect: to.fullPath } })
    return
  }

  next()
})

export default router
